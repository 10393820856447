import React from 'react';
import classes from './Form.module.scss';

const ErrorMessage = ({ children, errorStyle }) => {
  if (typeof children !== 'string') return null;

  return (
    <p className={(classes.errorMassage, errorStyle, 'errorStyle')}>
      {children}
    </p>
  );
};

export default React.memo(ErrorMessage);
