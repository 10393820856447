import React from 'react';
import { Formik, Form } from 'formik';
import TranslationForm from './TranslationForm';

const AppForm = ({ initialValues, onSubmit, validationSchema, children }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(...props) => (
        <TranslationForm form={props[0]}>
          <Form noValidate>{children(...props)}</Form>
        </TranslationForm>
      )}
    </Formik>
  );
};

export default React.memo(AppForm);
