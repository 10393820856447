import create from 'zustand';
import { communityController } from '../services/controllers';
export const useCommunityStore = create((set, get) => ({
  community: [],
  states: [],
  communityGet: async (stateId) => {
    try {
      const community = await communityController.community(stateId);

      set({ community });
    } catch (error) {
      console.log(error);
    }
  },
  statesGet: async () => {
    try {
      const states = await communityController.getStates();

      set({ states });
    } catch (error) {
      console.log(error);
    }
  }
}));
