import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import classes from '../HelperPerson/HelperPersone.module.scss';

const HelperPerson = ({
  isShowed = true,
  isRight = false,
  overlay,
  hideAfter,
  name,
  isShowAllText = false,
  personSpeech,
  isLanguageSelect = false,
  speechEffect = true,
  className
}) => {
  const [placeholder, setPlaceholder] = useState('');

  const [isShowedHelperPerson, setShowedHelperPerson] = useState(true);

  const { selectedPerson } = usePlayerStore();
  const { a } = useUserStore();
  const string = personSpeech || '';

  useEffect(() => {
    let addCharInterval;
    let hideAfterTimeout;

    const timeout = setTimeout(() => {
      let index = 0;

      const tick = () => {
        isShowAllText
          ? setPlaceholder(string)
          : setPlaceholder((prev) => prev + (string[index] || ''));
        index++;

        if (index > string.length && addCharInterval)
          clearInterval(addCharInterval);
      };

      addCharInterval = setInterval(tick, 50);
    }, 1000);

    if (hideAfter) {
      hideAfterTimeout = setTimeout(() => {
        setShowedHelperPerson(false);
      }, hideAfter);
    }
    console.log('selectedPerson===>', selectedPerson);
    return () => {
      clearTimeout(timeout);

      if (isShowed) sessionStorage.setItem(name, 'true');

      if (hideAfterTimeout) clearTimeout(hideAfterTimeout);
      if (addCharInterval) clearInterval(addCharInterval);
    };
  }, []);

  if (isShowed) isShowed = sessionStorage.getItem(name) !== 'true';

  if (!isShowedHelperPerson) sessionStorage.setItem(name, 'true');

  if (!isShowedHelperPerson || !isShowed) return null;

  return (
    <div
      className={cx(
        classes.HelperPerson,
        {
          [classes.HelperPersonRight]: isRight,
          [classes.HelperPersonSelectLanguage]: isLanguageSelect
        },
        className
      )}
    >
      <div className={overlay ? classes.Overlay : classes.OverlayNone}></div>
      <div className={classes.Container}>
        <div className={classes.Person}>
          <div className='animate__backInUp'>
            <img src={`${selectedPerson}_Talking.svg`} alt='' />
          </div>
        </div>
        <div className='animate__backInDown'>
          <div className='chat-box'>
            <div className={classes.ChatText}>
              <p>{speechEffect ? placeholder : personSpeech}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelperPerson;
