import apiService from '../api';

class CommunityController {
  async community(stateId) {
    try {
      const communityResponse = await apiService.get(
        `/Player/GetCommunities?stateId=${stateId}`
      );

      return communityResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async getStates() {
    try {
      const statesResponse = await apiService.get('/Player/GetStates');

      return statesResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const communityController = new CommunityController();

export default communityController;
