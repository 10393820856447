import apiService from "../api";

// Auth
class UserController {
  async login(userData) {
    try {
      const userInfoResponse = await apiService.post("/Auth/Login", userData);

      return userInfoResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async reset(userData) {
    try {
      const { data: userInfo } = await apiService.post(
        "/Auth/ResetPassword",
        userData
      );

      return userInfo.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async register(userData) {
    try {
      const { data: userInfo } = await apiService.post(
        "/Auth/Register",
        userData
      );

      return userInfo.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const userController = new UserController();

export default userController;
