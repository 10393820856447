import preloadImages from './preloadImages';

const preloadPersonImages = () => {
  preloadImages(
    require('../images/svg/chat-box-right.svg'),
    require('../images/svg/chat-box.svg')
  );
};

export default preloadPersonImages;
