import apiService from '../api';

class GameController {
  async getGame(gameData) {
    try {
      const gameResponse = await apiService.post('/Game/GetGame', gameData);

      return gameResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async checkGameAnswer(answerData) {
    try {
      const checkResponse = await apiService.post(
        '/Game/CheckQuestionAnswer',
        answerData
      );

      return checkResponse.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async completeGame(gameId) {
    try {
      const complete = await apiService.get(
        `Game/CompleteGame?gameId=${gameId}`
      );

      return complete.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }

  async certificateGet(level) {
    try {
      const certificat = await apiService.get(
        `Game/GetCertificateText?level=${level}`
      );

      return certificat.data.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const gameController = new GameController();

export default gameController;
