import create from 'zustand';

export const useSectionStore = create((set, get) => ({
  questions: [],
  section: false,
  currentStep: 1,
  percent: 0,
  isShowedButtonLoading: false,
  rightQuestionAnswer: null,
  isBlockedAnswerClick: false,
  score: 0,
  selectedGameType: '',
  certificateInfo: [],
  setSelectedGameType: (gameType) => set({ selectedGameType: gameType }),

  getSection: async (section, onSuccess = () => {}, onError = () => {}) => {
    try {
      set({
        section: section
      });

      onSuccess();
    } catch (error) {
      console.log(error);
      onError();
    }
  }
}));
