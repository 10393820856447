import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import classes from '../LoadingIndicator/LoadingIndicator.module.scss';


const LoadingIndicator = ({ onEnd }) => {
  const [value, setValue] = useState(0);
  const { getPlayerInfo } = usePlayerStore();
  const { user } = useUserStore();

  const history = useHistory();

  const { i18n } = useTranslation();

  useEffect(() => {
    let scopedValue = 0;
    const interval = setInterval(() => {
      if (scopedValue === 10) {
        clearInterval(interval);
        onEnd();
        return;
      }
      scopedValue++;
      setValue(scopedValue + 10);
    }, 50);
  }, []);
  useEffect(() => {
    if (user)
      getPlayerInfo(
        (language) => i18n.changeLanguage(language),
        () => history.replace('/questions')
      );
  }, []);
  return (
    <div className={classes.LoadingIndicator}>
      {/* <div className='text-center'>
        <Logo />
      </div> */}
      <div
        style={{
          color: 'white',
          fontSize: '18px',
          fontWeight: '500',
          marginTop: '32px',
          textAlign: 'center'
        }}
      >
        Loading...
      </div>

      <div
        className='progress mt-4'
        style={{
          position: 'relative',
          width: '100%',
          borderRadius: '19px',
          height: '16px',
          backgroundColor: 'transparent',
          border: 'solid 1px #fff',
        }}
      >
        <div
          className='progress-bar'
          role='progressbar'
          style={{
            width: `${value}%`,
            backgroundColor: '#fff',
            position: 'absolute',
            height: '8px',
            top: '3px',
            left: '3px',
            borderRadius: '19px'
          }}
          aria-valuenow='25'
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
