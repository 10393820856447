import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CardItem } from '../../components';
import { useGameStore } from '../../store/game';
import { usePlayerStore } from '../../store/player';
import classes from '../QuestionsPage/QuestionsPage.module.scss';
import { CustomSideNav, ModalHappy, Progress } from '../shared';
import ModalSad from '../shared/ModalGroup/ModalSad';

let lastAnswer;

const QuestionsPage = () => {
  const {
    currentStep,
    getCurrentQuestion,
    checkGame,
    isBlockedAnswerClick,
    rightQuestionAnswer,
    percent,
    score,
    id
  } = useGameStore();
  const question = getCurrentQuestion();
  const { getPlayerInfo } = usePlayerStore();
  const { t } = useTranslation();
  const history = useHistory();

  if (!id) {
    history.replace('/');
    return null;
  }

  const handleCheckAnswer = (answer) => {
    if (isBlockedAnswerClick) return;

    lastAnswer = answer;

    checkGame(
      {
        gameId: id,
        questionId: question._id,
        answer
      },
      () => {
        getPlayerInfo();
      }
    );
  };

  return (
    <>
      <div className={classes.Nav}>
        <CustomSideNav />
      </div>
      <div className={classes.QuestionsPage}>
        {currentStep === 11 && percent >= 50 ? (
          <ModalHappy
            percent={percent}
            score={score}
            defaultOpened
            color='yellow'
          />
        ) : null}
        {currentStep === 11 && percent < 50 ? (
          <ModalSad percent={percent} defaultOpened />
        ) : null}

        <div className={classes.Question}>
          <h2 className={classes.Title}>
            {t('text.question')}{' '}
            <span>{currentStep < 11 ? currentStep : 10}</span>/10
          </h2>
          <Progress currentStep={currentStep < 11 ? currentStep : 10} />
        </div>
        {question && (
          <>
            <div className={classes.QuestionsItem}>
              <CardItem>
                <p>{question.question}</p>
              </CardItem>
            </div>
            <div className={classes.QuestionItemGroup}>
              {question?.answers?.map((qq, idx) => {
                return (
                  <CardItem
                    key={idx}
                    rounded
                    handleCheck={() => handleCheckAnswer(qq.answer)}
                    selected={rightQuestionAnswer === qq.answer}
                    error={
                      rightQuestionAnswer &&
                      rightQuestionAnswer !== qq.answer &&
                      lastAnswer === qq.answer
                    }
                  >
                    <p key={idx}>{qq.answer}</p>
                  </CardItem>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(QuestionsPage);
