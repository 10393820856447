import { MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCommunityStore } from '../../../store/community';
import { usePlayerStore } from '../../../store/player';
import { CustomSelectUI } from '../CustomSelectUI';

const StatesSelect = (props) => {
  const { statesGet, states } = useCommunityStore();

  const { selectedLanguage } = usePlayerStore();

  useEffect(() => {
    statesGet();
  }, []);

  return (
    <CustomSelectUI {...props}>
      {states?.data?.map((state) => {
        const label = state.translations?.find(
          (t) => t.language === selectedLanguage
        )?.name;

        return (
          <MenuItem key={state.id} value={`${state.id}-${label}`}>
            {label}
          </MenuItem>
        );
      })}
    </CustomSelectUI>
  );
};

export default StatesSelect;
