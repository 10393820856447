import React, { useEffect, useMemo, useState } from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { usePlayerStore } from '../../../store/player';
import classes from './Slider.module.scss';

const CustomArrow = (props) => {
  const { className, style, onClick, disabled } = props;
  return (
    <button
      disabled={disabled}
      className={className}
      style={style}
      onClick={onClick}
    />
  );
};

const Slider = ({ persons, onChange, initialSlide = 0 }) => {
  const { setSelectedPerson } = usePlayerStore();

  if (initialSlide < 0) initialSlide = 0;

  const [currentIndex, setCurrentIndex] = useState(initialSlide + 1);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CustomArrow />,
      prevArrow: <CustomArrow />,
      afterChange: (index) => {
        setCurrentIndex(index + 1);
      },
      initialSlide
    }),
    [persons, currentIndex]
  );

  useEffect(() => {
    onChange(persons[currentIndex - 1]);
  }, [currentIndex, persons]);

  return (
    <div className={classes.SliderContainer}>
      <div className={classes.SlideCount}>
        <span className={classes.CurrentSlide}>
          <span className={classes.CurrentSlideCount}>{currentIndex}</span>/
        </span>
        {persons.length}
      </div>
      <SlickSlider {...sliderSettings}>
        {persons.map((p) => (
          <div key={p._id} onClick={() => setSelectedPerson(p.path)}>
            <img src={`${p.path}_Chosen.svg`} alt='' />
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default React.memo(Slider);
