import React from 'react';
import classes from './Card.module.scss';
import cx from 'classnames';
const Card = ({ children, classStyle, ...props }) => {
  return (
    <div className={cx(classes.Card, classStyle)} {...props}>
      {children}
    </div>
  );
};
export default React.memo(Card);
