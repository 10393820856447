const preloadImages = (...preloadedImages) => {
  const images = [];

  for (let i = 0; i < preloadedImages.length; i++) {
    images[i] = new Image();
    images[i].src = preloadedImages[i].default;
  }
};

export default preloadImages;
