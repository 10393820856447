import { ThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Spinner from './components/shared/Spinner/Spinner';
import { muiTheme } from './configs/ui';
import i18n from './i18n';
import './index.scss';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Suspense fallback={<Spinner />}>
      <ThemeProvider theme={muiTheme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Suspense>
  </I18nextProvider>,
  document.getElementById('root')
);
