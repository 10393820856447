import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { CustomInput, CustomSelect } from '../shared';
import { default as CustomErrorMessage } from './ErrorMessage';

const AppFormField = ({
  name,
  wrapperClassName,
  renderField,
  errorStyle,
  type,
  placeholder,
  ...otherProps
}) => {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <>
              <div>
                {renderField ? (
                  renderField({
                    ...field,
                    ...otherProps,
                    error: meta.touched && meta.error,
                    onChange: (...args) => {
                      field.onChange(...args);
                      if (otherProps.onChange) otherProps.onChange(...args);
                    }
                  })
                ) : (
                  <>
                    {type !== 'select' ? (
                      <>
                        <CustomInput
                          {...field}
                          {...otherProps}
                          onChange={(...args) => {
                            field.onChange(...args);
                            if (otherProps.onChange)
                              otherProps.onChange(...args);
                          }}
                          error={meta.touched && meta.error}
                          placeholder={placeholder}
                          type={type}
                        />
                      </>
                    ) : (
                      <>
                        <CustomSelect
                          community={[{ name: 'adsd' }, { name: '21111adsd' }]}
                          medium
                          placeholder={placeholder}
                          {...field}
                          {...otherProps}
                          onChange={(e) => {
                            form.setFieldValue(name, e.target.value);
                            form.setFieldTouched(name, true);

                            field.onChange(e);

                            if (otherProps.onChange) otherProps.onChange(e);
                          }}
                          error={meta.touched && meta.error}
                        />
                      </>
                    )}
                  </>
                )}

                <ErrorMessage
                  name={name}
                  component={CustomErrorMessage}
                  errorStyle={errorStyle}
                />
              </div>
            </>
          );
        }}
      </Field>
    </>
  );
};

export default React.memo(AppFormField);
