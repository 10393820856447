import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

let latestForm;

const TranslationForm = ({ children }) => {
  const { i18n } = useTranslation();
  const form = useFormikContext();

  latestForm = form;

  useEffect(() => {
    const onLanguageChanged = () => {
      setTimeout(() => {
        Object.keys(latestForm.touched).forEach((fieldName) => {
          latestForm.setFieldTouched(fieldName);
        });
      }, 100);
    };

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, []);

  return children;
};

export default React.memo(TranslationForm);
