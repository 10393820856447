import { default as classNames, default as cx } from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import { Card } from '../Card';
import classes from '../ChoosePersons/ChoosePersons.module.scss';
import { Contact } from '../Contact';
import { CustomButton, Slider, Title } from '../shared';

const ChoosePersons = () => {
  const history = useHistory();
  const { playerGet, person, selectedPerson, setSelectedPerson, selectPerson } =
    usePlayerStore();

  const { user } = useUserStore();
  const { t } = useTranslation();
  const [id, setId] = useState(null);

  useEffect(() => {
    playerGet();
  }, []);

  return (
    <div className={classes.ChoosePersons}>
      <Card classStyle={classes.Card}>
        <div className={classes.ContactSection}>
          <Contact position />
        </div>

        <div className={classes.PersonsContact}>
          <div className='d-block d-lg-none'>
            <div
              className={
                ('d-flex d-lg-none justify-content-end align-items-center',
                classes.HeaderContainer)
              }
            >
              {/* <div className={classes.HeaderLogoMobile}>
                <HeaderLogo />
              </div> */}
              {/* <ContactUsButton onClick={() => history.push('/contact-us')} /> */}
            </div>
            <div className={classes.TitleContainer}>
              <Title className={classes.Title}>{t('text.choose_person')}</Title>
            </div>
          </div>
          <div
            className={classNames('d-none d-lg-flex justify-content-between')}
            style={{ marginBottom: '89px' }}
          >
            <div className={classNames(classes.TitleContainer)}>
              <Title>{t('text.choose_person')}</Title>
              {/* <Contact /> */}
            </div>
          </div>
        </div>
        <div className={classes.Persons}>
          {person ? (
            <>
              <div
                className='d-none d-lg-flex flex-no-wrap justify-content-between m-0'
                style={{ gap: '8px' }}
              >
                {person?.map((p) => {
                  return (
                    <div
                      key={p._id}
                      onClick={() => {
                        setSelectedPerson(p.path);
                        setId(p?._id);
                      }}
                    >
                      <div
                        className={cx(classes.Person, {
                          [classes.PersonSelected]: selectedPerson === p.path
                        })}
                      >
                        <img
                          src={`${p.path}.svg`}
                          alt=''
                          className={cx({
                            'd-block': selectedPerson !== p.path,
                            'd-none': selectedPerson === p.path
                          })}
                        />
                        <img
                          src={`${p.path}_Chosen.svg`}
                          alt=''
                          className={cx({
                            'd-block': selectedPerson === p.path,
                            'd-none': selectedPerson !== p.path
                          })}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className='d-block d-lg-none'>
                <Slider
                  initialSlide={person.findIndex(
                    (p) => p.path === selectedPerson
                  )}
                  persons={person}
                  onChange={(p) => {
                    setSelectedPerson(p?.path);
                    setId(p?._id);
                  }}
                />
              </div>
            </>
          ) : (
            <div class='spinner-border' role='status'></div>
          )}
          <div className={classes.ChooseBtn}>
            <CustomButton
              title={user ? `${t('text.change')}` : `${t('text.next')}`}
              onClick={() => {
                selectPerson();
                history.push('/login');

                localStorage.setItem('personId', id);
              }}
              disabled={selectedPerson === '' ? true : false}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default React.memo(ChoosePersons);
