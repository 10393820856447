import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Contact, LanguageSelect } from '..';
import { showAlert } from '../../helpers/alerts';
import { Medal, Medal_Active_1, WhiteLogo } from '../../images/svg';
import { useGameStore } from '../../store/game';
import { usePlayerStore } from '../../store/player';
import { useSectionStore } from '../../store/section';
import { useUserStore } from '../../store/users';
import { Card } from '../Card';
import classes from '../ChoosePosition/ChoosePosition.module.scss';
import { CustomButton, CustomSideNav, Title } from '../shared';

const ChoosePosition = () => {
  const { getSection } = useSectionStore();

  const { isShowedButtonLoading, setSelectedGameType } = useGameStore();
  const {
    selectedPerson,
    setSelectedPerson,
    user,
    selectedLanguage,
    ...playerStore
  } = usePlayerStore();
  const {
    getGame,
    // isShowedButtonLoading,
    selectedGameType
    // id,
    // setSelectedGameType
  } = useGameStore();

  const { activeResidentOfTheCommunity, candidateForTheCouncilOfElders } =
    playerStore[
      selectedGameType[0].toLowerCase() + selectedGameType.slice(1)
    ] || {};
  const { a } = useUserStore();
  const [set1, showSet1] = useState(null);
  const [set2, showSet2] = useState(null);
  const [set, showSet] = useState(null);
  const { t } = useTranslation();
  const [sectionn, setSection] = useState('');
  const history = useHistory();

  const [position, setPosition] = useState('');

  const first = () => {
    if (activeResidentOfTheCommunity) return;
    showSet1(true);
    showSet2(false);
    setPosition('ActiveResidentOfTheCommunity');
  };

  const second = () => {
    if (candidateForTheCouncilOfElders) return;

    showSet2(true);
    showSet1(false);
    setPosition('CandidateForTheCouncilOfElders');
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!selectedPerson) history.replace('/');
  }, []);

  return (
    <div className={classes.ChoosePosition}>
      <Card>
        <div className={classes.PositionHeader}>
          <div className='d-none d-lg-flex justify-content-end'>
            <LanguageSelect />
            <Contact />
          </div>
        </div>
        <div className={classes.CardHeader}>
          <div className='d-flex d-lg-none justify-content-between align-items-center'>
            <div className={classes.CardHeaderLogo}>
              <WhiteLogo />
            </div>
            <CustomSideNav />
          </div>
        </div>

        <div className={classes.PositionGroupWrapper}>
          <div className={classes.PositionGroupContainer}>
            <div className={classes.Title}>
              <Title>{t('text.choose_position')}</Title>
            </div>
            <div className={classes.PositionGroup}>
              <div
                onClick={first}
                style={
                  set1
                    ? { border: '2px solid #FFB400' }
                    : { border: '1px solid white' }
                }
                className={`${
                  activeResidentOfTheCommunity ? classes.CompletedGame : ''
                }`}
              >
                {activeResidentOfTheCommunity && (
                  <div className={classes.CheckMedal}>
                    <Medal_Active_1 />
                  </div>
                )}
                <p style={set1 ? { color: '#FFB400' } : { color: 'white' }}>
                  {t('text.lvl1')}
                </p>
              </div>
              <div
                onClick={second}
                style={
                  set2
                    ? { border: '2px solid #FFB400' }
                    : { border: '1px solid white' }
                }
                className={`${
                  candidateForTheCouncilOfElders ? classes.CompletedGame : ''
                }`}
              >
                {candidateForTheCouncilOfElders && (
                  <div className={classes.CheckMedal}>
                    <Medal />
                  </div>
                )}
                <p style={set2 ? { color: '#FFB400' } : { color: 'white' }}>
                  {t('text.lvl2')}
                </p>

                {candidateForTheCouncilOfElders && (
                  <Link
                    to='/certification/gfdhh'
                    className={classes.CertificateLink}
                  >
                    {/* <ChooseSection /> */}
                  </Link>
                )}
              </div>
            </div>

            <div>
              <CustomButton
                buttonIcon={isShowedButtonLoading ? 'spinner-border' : ''}
                title={isShowedButtonLoading ? '' : `${t('text.next')}`}
                className={cx(
                  set1 || set2
                    ? classes.CustomButtonAllow
                    : classes.CustomButton,
                  {
                    [classes.CustomButtonCompleted]:
                      candidateForTheCouncilOfElders
                  }
                )}
                disabled={set1 || set2 ? false : true}
                onClick={() => {
                  setSelectedGameType(position);
                  getGame(
                    {
                      language: selectedLanguage,
                      // id: _id,
                      level: position,
                      section: selectedGameType
                    },
                    () => history.push('/questions'),
                    () => showAlert('error', t('text.not_question'))
                  );
                }}
              />
            </div>
          </div>
        </div>

        {/* {candidateForTheCouncilOfElders && activeResidentOfTheCommunity
          ? null
          : !user &&
            selectedPerson && (
              <HelperPerson
              name='register-helper'
                overlay
                hideAfter={5000}
                isRight
                personSpeech={t('text.choose_level')}
              />
            )} */}
      </Card>
    </div>
  );
};

export default ChoosePosition;
