import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN_LS } from '../../../configs/storage';
import { CloseSide, Hamburger, LogOutSide } from '../../../images/svg';
import { useUserStore } from '../../../store/users';
import { CustomSelect } from '../CustomSelect';
import customClasses from './CustomSideNav.module.scss';

const useStyles = makeStyles({
  fullList: {
    width: 'auto'
  }
});

const onLogoutClick = () => {
  localStorage.removeItem(ACCESS_TOKEN_LS);
  sessionStorage.removeItem('language-select');
  sessionStorage.removeItem('login-helper');
  sessionStorage.removeItem('choose-position');
  window.location.reload();
};

export default React.memo(function TemporaryDrawer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUserStore();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(customClasses.CustomSideNavWrapper, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation'
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        className='text-right mt-3 mr-3'
        onClick={toggleDrawer(anchor, false)}
      >
        <CloseSide />
      </List>
      <List className='ml-3'>
        <span
          style={{ fontSize: '1.8rem', fontWeight: '900', color: '#433466' }}
          className={customClasses.test}
        >
          {t('text.change_language')}
        </span>
      </List>
      <List className='ml-3'>
        <CustomSelect
          am='Հայերեն'
          ru='Русский'
          en='English'
          className={customClasses.LanguageSelect}
        />
      </List>
      {/* <List className='ml-3' onClick={toggleDrawer(anchor, false)}>
        <Link to='/contact-us' className='ml-0'>
          <span
            style={{ fontSize: '1.8rem', fontWeight: '900', color: '#433466' }}
          >
            {t('text.contactWithUs')}
          </span>
        </Link>
      </List> */}
      <List className='ml-3 mb-3'>
        <Link to='/persons' className='ml-0'>
          <span
            style={{
              fontSize: '1.8rem',
              fontWeight: '900',
              color: '#433466',
              marginTop: '2.4rem',
              position: 'relative',
              top: '0.2rem'
            }}
          >
            {t('text.settings')}
          </span>
        </Link>
      </List>
      {user && (
        <>
          <Divider style={{ width: '282px', margin: 'auto' }} />
          <List className='ml-3 logoutWrapper '>
            <LogOutSide className='logout-icon' />
            <Link className='ml-2 logoutWrapper'>
              <span
                style={{
                  fontSize: '1.8rem',
                  fontWeight: '900',
                  color: '#433466'
                  // marginTop: '2.5rem'
                }}
              >
                <span
                  onClick={onLogoutClick}
                  style={{ paddingTop: '2.5rem' }}
                  className='mt-3'
                >
                  {' '}
                  {t('text.exit')}{' '}
                </span>
              </span>
            </Link>
          </List>{' '}
        </>
      )}
    </div>
  );

  return (
    <div>
      <Button
        className={customClasses.ButtonHamburger}
        onClick={toggleDrawer('right', true)}
      >
        <Hamburger />
      </Button>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
});
