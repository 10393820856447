import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Contact, LanguageSelect } from '..';
import { showAlert } from '../../helpers/alerts';
import { WhiteLogo } from '../../images/svg';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import UserLoginValidation from '../../validation/UserLoginValidation';
import { AppForm, AppFormField } from '../Forms';
import { HelperPerson } from '../HelperPerson';
import { CustomSideNav, Title } from '../shared';
import { CustomButton } from '../shared/CustomButton';
import StatesSelect from '../shared/StatesSelect/StatesSelect';
import classes from './Login.module.scss';

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    user,
    login,
    isLoginInvalidParametrs,
    isLoginButtonLoading,
    changeLoginInvalidParametr
  } = useUserStore((state) => state);
  const {
    selectedValue,
    setSelectedValue,
    setPlayerLang,
    changePlayerLang,
    selectedLanguage
  } = usePlayerStore();
  const { selectedPerson } = usePlayerStore();

  useEffect(() => {
    user
      ? history.replace('/')
      : history.replace(selectedPerson ? '/login' : '/');
  }, [user, history]);

  const clearInvalidParameter = useCallback(() => {
    changeLoginInvalidParametr(false);
  }, [isLoginInvalidParametrs]);

  return (
    <Card>
      <div style={{ overflowY: 'hidden' }}>
        <div className={classes.LoginHeader}>
          <div className='d-none d-lg-flex justify-content-end'>
            <LanguageSelect />
            <Contact />
          </div>
        </div>
        <div className={classes.CardHeader}>
          <div className='d-flex d-lg-none justify-content-between align-items-center'>
            <div className={classes.CardHeaderLogo}>
              <WhiteLogo />
            </div>
            <CustomSideNav />
          </div>
        </div>
        <div className={classes.LoginContainer}>
          <div className={classes.LoginWrapper}>
            <Title> {t('text.fill')}</Title>
            <AppForm
              initialValues={{
                firstName: '',
                lastName: '',
                middleName: '',
                community: '',
                age: '',
                phone: '',
                email: '',
                residence: '',
                state: ''
              }}
              onSubmit={(values) =>
                login({ ...values, state: values.state?.split('-')[1] }, () =>
                  showAlert('error', t('text.invalid_parametrs'))
                )
              }
              validationSchema={UserLoginValidation(t)}
            >
              {(form) => {
                return (
                  <>
                    <div className={classes.CustomInputGroup}>
                      <AppFormField
                        type='text'
                        title={t('text.register_name')}
                        placeholder={t('text.register_name')}
                        name='firstName'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        title={t('text.register_surname')}
                        placeholder={t('text.register_surname')}
                        name='lastName'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        title={t('text.register_middleName')}
                        placeholder={t('text.register_middleName')}
                        name='middleName'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        name='state'
                        type='select'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                        renderField={({ error, value }) => (
                          <StatesSelect
                            value={value}
                            error={error}
                            onBlur={() => form.setFieldTouched('state', true)}
                            onChange={(e) => {
                              form.setFieldTouched('state', true);
                              form.setFieldValue('state', e.target.value);
                              form.setFieldValue('community', '');
                            }}
                            placeholder={t('text.register_state')}
                            label={t('text.register_state')}
                          />
                        )}
                      />
                      <AppFormField
                        title={t('text.register_community')}
                        placeholder={t('text.register_community')}
                        name='community'
                        type='select'
                        state={form.values.state?.split('-')[0] || null}
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        type='text'
                        title={t('text.register_residence')}
                        placeholder={t('text.register_residence')}
                        name='residence'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        type='text'
                        title={t('text.register_age')}
                        placeholder={t('text.register_age')}
                        name='age'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        type='text'
                        title={t('text.register_email')}
                        placeholder={t('text.register_email')}
                        name='email'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                      <AppFormField
                        type='tel'
                        title={t('text.register_phone')}
                        placeholder={t('text.register_phone')}
                        name='phone'
                        onChange={clearInvalidParameter}
                        errorStyle={classes.errorStyle}
                      />
                    </div>

                    <div className={classes.Footer}>
                      {/* <div className={classes.Footer_link}>
                        <p>
                          {t('text.dontYou')}
                          <span onClick={() => history.push('/register')}>
                            {t('text.register')}
                          </span>
                        </p>
                      </div> */}
                      <div className={classes.FooterText}>
                        {t('text.pravite_information')}
                      </div>

                      <div>
                        <CustomButton
                          buttonIcon={
                            isLoginButtonLoading ? 'spinner-border' : ''
                          }
                          title={
                            isLoginButtonLoading ? '' : `${t('text.next')}`
                          }
                          className={classes.CustomButton}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </AppForm>
          </div>
        </div>
      </div>
      {!user && selectedPerson && (
        <HelperPerson
          name='login-helper'
          overlay
          isRight
          isShowAllText
          hideAfter={10000}
          speechEffect={false}
          personSpeech={
            <>
              {t('text.about_game2')} <br /> {t('text.heading_game')}
            </>
          }
          className={classes.HelperPerson}
        />
      )}
    </Card>
  );
};
export default React.memo(Login);
