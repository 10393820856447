import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ACCESS_TOKEN_LS } from '../../configs/storage';
import { showAlert } from '../../helpers/alerts';
import { LogOut, Logo, Setting, WhiteLogo } from '../../images/svg';
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg';
import { useEmailStore } from '../../store/email';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import ContactUsValidation from '../../validation/ContactUsValidation';
import { AppForm, AppFormField } from '../Forms';
import { CustomButton, CustomTextarea } from '../shared';
import classes from './Contact.module.scss';

const Contact = ({ position }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { selectedPerson } = usePlayerStore();
  const { user } = useUserStore();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onLogoutClick = () => {
    localStorage.removeItem(ACCESS_TOKEN_LS);
    sessionStorage.removeItem('language-select');
    sessionStorage.removeItem('login-helper');
    sessionStorage.removeItem('choose-position');
    window.location.reload();
  };

  const {
    isShowedButtonLoading,
    email,
    isSuccess,
    isShowedInvalidParameters,
    changeInvalidParameter,
    setIsSuccess
  } = useEmailStore((state) => state);

  const clearInvalidParameter = useCallback(() => {
    changeInvalidParameter(false);
  }, [isShowedInvalidParameters]);

  return (
    <div className={classes.Contact}>
      <div className={classes.ContactLogo}>
        <WhiteLogo />
      </div>
      {location.pathname === '/questions' && (
        <div className={classes.QuestionsLogo}>
          <Logo />
        </div>
      )}
      {/* <ContactUsButton onClick={handleClickOpen} /> */}

      {location.pathname === '/questions' ? (
        <>
          <Setting className={classes.LogOutButton} />
        </>
      ) : (
        <>
          {(user && location.pathname === '/') ||
          location.pathname === '/' ? null : (
            <Link to='/'>
              <Setting
                className={classNames(classes.LogOutButton, {
                  [classes['LogOutButton--position']]: position
                })}
              />
            </Link>
          )}
        </>
      )}
      {user && (
        <LogOut className={classes.LogOutButton} onClick={onLogoutClick} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.ContactModal}
      >
        <div className={classes.ModalHeader}>
          <h2 className={classes.Title}>{t('text.contactWithUs')}</h2>
          <CloseIcon onClick={handleClose} />
        </div>

        <AppForm
          initialValues={{
            name: '',
            email: '',
            msg: ''
          }}
          onSubmit={(values, { resetForm }) => {
            email(values, () => showAlert('success', t('text.success_sent')));
            resetForm({ values: '' });
          }}
          validationSchema={ContactUsValidation(t)}
        >
          {(form) => {
            if (isSuccess)
              setTimeout(() => {
                setIsSuccess(false);
              }, 1500);

            return (
              <>
                <div className={classes.ModalBody}>
                  <div className={classes.CustomInputGroup}>
                    <AppFormField
                      title={t('text.name')}
                      placeholder={t('text.name')}
                      name='name'
                      maxLength={21}
                      errorStyle={classes.errorStyle}
                      onChange={clearInvalidParameter}
                    />
                    <AppFormField
                      title={t('text.mail')}
                      placeholder={t('text.mail')}
                      name='email'
                      errorStyle={classes.errorStyle}
                      onChange={clearInvalidParameter}
                    />
                  </div>
                  <div className={classes.CustomTextarea}>
                    <AppFormField
                      title={t('text.letter')}
                      placeholder={`${t('text.letter')}...`}
                      multiline
                      name='msg'
                      errorStyle={classes.errorStyle}
                      renderField={(props) => (
                        <>
                          <CustomTextarea {...props} />
                        </>
                      )}
                      onChange={clearInvalidParameter}
                    />
                  </div>
                  <div className={classes.ModalFooter}>
                    <CustomButton
                      buttonIcon={isShowedButtonLoading ? 'spinner-border' : ''}
                      title={isShowedButtonLoading ? '' : `${t('text.send')}`}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </AppForm>
      </Dialog>
    </div>
  );
};

export default React.memo(Contact);
