import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { checkIsDesktop } from '../src/helpers';
import classes from './App.module.scss';
import {
  Card,
  ChoosePersons,
  ChoosePosition,
  ChooseSection,
  Header,
  LoadingIndicator,
  Login,
  QuestionsPage,
  SelectLanguageSection
} from './components';
import Certification from './components/Certification/Certification';
import ContactUsMobile from './components/Contact/MobileContact';
import {
  addElementScrollableListener,
  changeRootSize,
  checkElementScrollable,
  preloadPersonImages
} from './helpers';
import { Logo, NgocLogo } from './images/svg';
import { useGameStore } from './store/game';
import { useSectionStore } from './store/section';
import { useUserStore } from './store/users';

function App() {
  const { user } = useUserStore();
  const { id } = useGameStore();
  const [isLoading, setIsLoading] = useState(true);
  const { section } = useSectionStore();

  const appWrapperEl = useRef(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const elementScrolableListenerRemover = addElementScrollableListener(
      appWrapperEl?.current
    );
    const rootSizeListenerRemover = changeRootSize(appWrapperEl?.current);

    preloadPersonImages();

    history.listen(() => window.scrollTo(0, 0));

    return () => {
      rootSizeListenerRemover();
      elementScrolableListenerRemover();
    };
  }, []);

  useEffect(() => {
    if (appWrapperEl?.current) {
      checkElementScrollable(appWrapperEl.current);

      history.listen(() => {
        checkElementScrollable(appWrapperEl.current);
      });
    }
  }, [isLoading]);

  const isOnGameLocation =
    !isLoading && (id || (user && location.pathname === '/'));

  const isOnCertificationLocation =
    window.innerWidth >= 991 && location.pathname.includes('/certification');

  const isOnRegisterPage = location.pathname === '/register';

  useEffect(() => {
    if (isOnRegisterPage) document.body.classList.add('scroll-lock');
    else document.body.classList.remove('scroll-lock');
  }, [isOnRegisterPage]);

  const { t } = useTranslation();

  return (
    <div
      className={cx('AppWrapper', classes.AppWrapper, {
        [classes.AppWrapperGame]: isOnGameLocation,
        [classes.AppWrapperRegister]: isOnRegisterPage
      })}
    >
      <div className={classes.AppContainer} ref={appWrapperEl}>
        {!isLoading && !isOnCertificationLocation && <Header />}
        {isLoading ? (
          <LoadingIndicator onEnd={() => setIsLoading(false)} />
        ) : (
          <>
            {id ? (
              checkIsDesktop() ? (
                <QuestionsPage />
              ) : (
                <Card>
                  <QuestionsPage />
                </Card>
              )
            ) : section && user ? (
              <Route exact component={ChoosePosition} path='/' />
            ) : user ? (
              <>
                <Switch>
                  {/* <Route exact component={Confidential} path='/' /> */}

                  {/* <Route exact component={ChoosePosition} path='/' /> */}
                  {/* <ChooseSection path='/sections' /> */}
                  <Route exact component={ChooseSection} path='/' />
                  <Route exact component={ChoosePersons} path='/persons' />
                  <Route exact component={ContactUsMobile} path='/contact-us' />
                  <Route
                    exact
                    component={Certification}
                    path='/certification/:gameType'
                  />
                  <Redirect to='/' />
                </Switch>
              </>
            ) : (
              <>
                <Switch>
                  <Route exact component={SelectLanguageSection} path='/' />
                  <Route exact component={Login} path='/login' />
                  {/* <Route exact component={Register} path='/register' /> */}
                  <Route exact component={ChoosePersons} path='/persons' />
                  <Route exact component={ContactUsMobile} path='/contact-us' />
                  <Redirect to='/' />
                </Switch>
              </>
            )}
          </>
        )}
        {!isLoading && !isOnCertificationLocation && (
          <div className={classes.Footer}>
            <div className={classes.FooterLogo1}>
              <Logo />
            </div>
            <div className={classes.FooterText}>{t('text.about_game')}</div>

            <div className={classes.FooterLogo2}>
              <NgocLogo />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default App;
