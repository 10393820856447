import React from 'react';
import classes from './Typography.module.scss';
import cx from 'classnames';

export const Title = React.memo(({ children, classNamee }) => {
  return <h1 className={cx(classes.Title, classNamee)}>{children}</h1>;
});

export const Paragraph = React.memo(({ children }) => {
  return <p className={classes.Paragraph}>{children}</p>;
});
