let timeout;

export const checkElementScrollable = (rootElem) => {
  if (timeout) clearTimeout(timeout);

  timeout = setTimeout(() => {
    const isRootElementScrollable =
      rootElem.scrollHeight + 10 > window.innerHeight;

    if (isRootElementScrollable) document.body.classList.add('scroll-lock');
    else document.body.classList.remove('scroll-lock');
  }, 500);
};

const addElementScrollableListener = (rootElem) => {
  checkElementScrollable(rootElem);
  new ResizeObserver(() => checkElementScrollable(rootElem)).observe(rootElem);
  window.addEventListener('resize', () => checkElementScrollable(rootElem));
  return () => window.removeEventListener('resize', checkElementScrollable);
};

export default addElementScrollableListener;
