import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';
import React from 'react';
import { Error } from '../../../images/svg';
import { ErrorMessage as CustomErrorMessage } from '../../Forms';
import classesStyles from './CustomTextarea.module.scss';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
});

const CustomTextarea = ({
  title = '',
  placeholder = 'placeholder',
  type = 'text',
  maxLength,
  error,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classesStyles.CustomTextareaContainer}>
        <div className={classesStyles.CustomTextarea}>
          <label htmlFor='outlined-basic'>
            {title} <span className='text-danger'>*</span>
          </label>
          <div className={classesStyles.root}>
            <TextField
              id='outlined-basic'
              type={type}
              className={error ? classesStyles.ValidationBorder : null}
              placeholder={placeholder}
              InputProps={{ classes }}
              inputProps={{ maxLength }}
              multiline
              {...props}
            />
            {error ? <Error className={classesStyles.ErrorIcon} /> : null}
          </div>
        </div>
      </div>

      <ErrorMessage
        name={props.name}
        component={CustomErrorMessage}
        errorStyle='errorStyle'
      />
    </>
  );
};
export default React.memo(CustomTextarea);
