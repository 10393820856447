import cx from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Contact, LanguageSelect } from '..';
import { showAlert } from '../../helpers/alerts';
import { Back, Logo } from '../../images/svg';
import { useEmailStore } from '../../store/email';
import ContactUsValidation from '../../validation/ContactUsValidation';
import classes from '../Contact/MobileContact.module.scss';
import { AppForm, AppFormField } from '../Forms';
import { CustomButton, CustomSideNav, CustomTextarea, Title } from '../shared';
const MobileContact = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    isShowedButtonLoading,
    email,
    isSuccess,
    isShowedInvalidParameters,
    changeInvalidParameter,
    setIsSuccess
  } = useEmailStore((state) => state);

  const clearInvalidParameter = useCallback(() => {
    changeInvalidParameter(false);
  }, [isShowedInvalidParameters]);

  return (
    <div className={classes.MobileContact}>
      <Card>
        <div className={classes.LoginHeader}>
          <div className='d-none d-lg-flex'>
            <LanguageSelect />
            <Contact />
          </div>
        </div>
        <div className={classes.CardHeader}>
          <div className='d-flex d-lg-none justify-content-end align-items-center'>
            <div className={classes.CardHeaderLogo}>
             {/* <Logo />*/}
            </div>
            <CustomSideNav />
          </div>
          <div className={classes.Title}>
            <button
              onClick={() => props.history.goBack()}
              className={classes.goBackMobile}
            >
              <Back />
            </button>
            <Title>{t('text.contactWithUs')}</Title>
          </div>
        </div>
        <AppForm
          initialValues={{
            name: '',
            email: '',
            msg: ''
          }}
          onSubmit={(values, { resetForm }) => {
            email(values, () => showAlert('success', t('text.success_sent')));
            resetForm({ values: '' });
          }}
          validationSchema={ContactUsValidation(t)}
        >
          {(form) => {
            if (isSuccess)
              setTimeout(() => {
                setIsSuccess(false);
              }, 1500);

            return (
              <>
                <div className={classes.ModalBody}>
                  <div className={classes.CustomInputGroup}>
                    <AppFormField
                      title={t('text.name')}
                      placeholder={t('text.name')}
                      name='name'
                      maxLength={21}
                      errorStyle={classes.errorStyle}
                      onChange={clearInvalidParameter}
                    />
                    <AppFormField
                      title={t('text.mail')}
                      placeholder={t('text.mail')}
                      name='email'
                      errorStyle={classes.errorStyle}
                      onChange={clearInvalidParameter}
                    />
                  </div>
                  <div className={classes.CustomTextarea}>
                    <AppFormField
                      title={t('text.letter')}
                      placeholder={`${t('text.letter')}...`}
                      multiline
                      name='msg'
                      errorStyle={classes.errorStyle}
                      renderField={(props) => <CustomTextarea {...props} />}
                      onChange={clearInvalidParameter}
                    />
                  </div>
                  <div className={cx(classes.ModalFooter, 'text-center')}>
                    <CustomButton
                      buttonIcon={isShowedButtonLoading ? 'spinner-border' : ''}
                      title={isShowedButtonLoading ? '' : `${t('text.send')}`}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </AppForm>
      </Card>
    </div>
  );
};

export default MobileContact;
