import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../images/svg/close.svg';
import { useGameStore } from '../../../store/game';
import { usePlayerStore } from '../../../store/player';
import { useSectionStore } from '../../../store/section';
import { CustomButton } from '../CustomButton';
import CustomIndicatorResult from '../CustomIndicatorResult/CustomIndicatorResult';
import classes from '../ModalGroup/ModalSad.module.scss';
const ModalSad = ({ percent = 50, defaultOpened }) => {
  const [open, setOpen] = useState(defaultOpened || false);
  const { selectedPerson } = usePlayerStore();

  const { section, getSection } = useSectionStore();

  const history = useHistory();

  const { resetId } = useGameStore();
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    getSection(null);
    resetId(percent);
    history.replace('/');
  };

  return (
    <div className={classes.ModalSad}>
      <Dialog open={open} onClose={handleClose} className={classes.Modal}>
        <div className={classes.ModalHeader}>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={classes.ModalBody}>
          <div className={classes.CircleGroup}>
            <CustomIndicatorResult percent={percent} color='red' />
            <span>{percent}%</span>
          </div>
          <div className={classes.Title}>
            <h2>{t('text.try_again')}</h2>
            <p>{t('text.try_again_text')}</p>
          </div>
          {/* <img
            src={`${selectedPerson}.svg`}
            className={classes.SadPerson}
            alt=''
          /> */}
          <div className='text-center mt-5'>
            <CustomButton
              title={t('text.play_again')}
              onClick={() => {
                resetId(percent);
                getSection(null);
                history.replace('/');
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(ModalSad);
