import React from 'react';
import classes from '../Language/Language.module.scss';
import { ArmFlag } from '../../../images/svg';

const Language = ({
  name = 'none',
  icon = <ArmFlag />,
  handleChosseLangiage,
  selectedClass
}) => {
  return (
    <div
      className={`${classes.Language} ${classes.SelectedLanguage} ${selectedClass}`}
      onClick={handleChosseLangiage}
    >
      <div>{icon}</div>
      <div>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default React.memo(Language);
