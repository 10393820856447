import cx from 'classnames';
import React from 'react';
import classes from '../CardItem/CardItem.module.scss';

const CardItem = ({ children, handleCheck, rounded, error, selected }) => {
  return (
    <div
      className={cx(classes.CardItem, {
        [classes.CardItemSelected]: selected,
        [classes.CardItemError]: error,
        [classes.CardItemRounded]: rounded
      })}
      onClick={handleCheck}
    >
      {children}
    </div>
  );
};

export default React.memo(CardItem);
