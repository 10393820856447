import React from 'react';
import { Gerb, Logo2, Logo3 } from '../../images/svg';
import { useGameStore } from '../../store/game';
import { Contact } from '../Contact';
import classes from './Header.module.scss';

const Header = () => {
  const { id } = useGameStore();

  return (
    <div className={classes.Header}>
      {/*   {!id && (
        <div>
          <Link to='/' className={classes.AppLogo}>
            <Logo className='d-none d-lg-block' />
          </Link>
        </div>
      )}*/}
      <div className={classes.LogosContainer}>
        {!id ? (
          <>
            <div className={classes.LogosContainerHeader}>
              <div className={classes.first}>
                <Logo2 />
              </div>
              <div className={classes.second}>
                <Gerb />
              </div>
              <div className={classes.third}>
                <Logo3 />
              </div>
            </div>
          </>
        ) : (
          <Contact />
        )}
      </div>
    </div>
  );
};

export default React.memo(Header);
