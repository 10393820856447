import { FormControl, MenuItem, Select } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Error } from '../../../images/svg';
import classes from './CustomSelectUI.module.scss';

const CustomSelectUI = ({ children, placeholder, label, error, ...props }) => {
  return (
    <div className={classes.CustomSelect}>
      {label && (
        <label htmlFor='outlined-basic'>
          {label} <span className='text-danger'>*</span>
        </label>
      )}

      <div
        className={classNames(classes.CustomSelectRoot, {
          [classes.ErrorBorder]: error
        })}
        id='outlined-basic'
      >
        <FormControl>
          <Select
            {...props}
            MenuProps={{
              className: classes.CustomSelectMenu
            }}
            disableUnderline
            displayEmpty
          >
            <MenuItem value='' disabled>
              <span className={classes.Placeholder}>{placeholder}</span>
            </MenuItem>
            {children}
          </Select>

          {error ? <Error className={classes.ErrorIcon} /> : null}
        </FormControl>
      </div>
    </div>
  );
};

export default CustomSelectUI;
