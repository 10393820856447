import apiService from "../api";

class EmailController {
  async email(emailData) {
    try {
      const emailResponse = await apiService.post(
        "/Email/SendEmail",
        emailData
      );

      return emailResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;

      return Promise.reject(errorInfo);
    }
  }
}

const emailController = new EmailController();

export default emailController;


