import create from 'zustand';
import { ACCESS_TOKEN_LS } from '../configs/storage';
import { playerController } from '../services/controllers';
import { useGameStore } from './game';
import { useUserStore } from './users';

export const usePlayerStore = create((set, get) => ({
  person: [],
  user: Boolean(localStorage.getItem(ACCESS_TOKEN_LS)),
  isShowedButtonLoading: false,
  annualWorkPlanOfCommunity: null,

  candidateForTheCouncilOfElders: true,
  activeResidentOfTheCommunity: false,
  communityBudget: false,
  communityAnnualWorkPlan: false,
  communityFiveYearPlan: false,

  selectedLanguage: 'Am',
  selectedValue: '',
  selectedPerson: '',
  setSelectedPerson: (selectedPerson) => set({ selectedPerson }),
  setPlayerLang: (selectedLanguage) => set({ selectedLanguage }),
  setSelectedValue: (selectedValue) => set({ selectedValue }),

  changePlayerLang: async () => {
    try {
      const selectedLanguage = get().selectedLanguage;

      await playerController.playerLang(selectedLanguage);
    } catch (error) {
      console.log(error);
    }
  },

  selectPerson: async () => {
    try {
      const selectedPersonId = get().person.find(
        (p) => p.path === get().selectedPerson
      );

      if (selectedPersonId && useUserStore.getState().user)
        await playerController.selectPerson(selectedPersonId._id);
    } catch (error) {
      console.log(error);
    }
  },

  changePerson: async () => {
    try {
      const selectedPersonId = get().person.find(
        (p) => p.path === get().selectedPerson
      );

      if (selectedPersonId && useUserStore.getState().user)
        await playerController.changePerson(selectedPersonId._id);
    } catch (error) {
      console.log(error);
    }
  },

  getPlayerInfo: async (onLangChange, onCurrentGameExist) => {
    try {
      const playerInfo = await playerController.playerInfo();

      if (playerInfo.currentLanguage) {
        set({ selectedLanguage: playerInfo.currentLanguage, playerInfo });
        if (onLangChange) onLangChange(playerInfo.currentLanguage);
      }

      set({
        candidateForTheCouncilOfElders:
          playerInfo.candidateForTheCouncilOfElders,
        activeResidentOfTheCommunity: playerInfo.activeResidentOfTheCommunity,
        selectedPerson: playerInfo.currentPerson,

        communityFiveYearPlan: playerInfo.communityFiveYearPlan,
        annualWorkPlanOfCommunity: playerInfo.annualWorkPlanOfCommunity,
        communityBudget: playerInfo.communityBudget,
        communityAnnualWorkPlanCompleted:
          playerInfo.annualWorkPlanOfCommunity.activeResidentOfTheCommunity &&
          playerInfo.annualWorkPlanOfCommunity.candidateForTheCouncilOfElders,
        communityFiveYearPlanCompleted:
          playerInfo.communityFiveYearPlan.activeResidentOfTheCommunity &&
          playerInfo.communityFiveYearPlan.candidateForTheCouncilOfElders,
        communityBudgetCompleted:
          playerInfo.communityBudget.activeResidentOfTheCommunity &&
          playerInfo.communityBudget.candidateForTheCouncilOfElders
      });

      if (playerInfo.currentGame) {
        useGameStore.setState({
          questions: playerInfo.currentGame.questions,
          id: playerInfo.currentGame._id,
          currentStep:
            playerInfo.currentGame.questions.findIndex(
              (q) => q.currentQuestion
            ) + 2,
          selectedGameType: playerInfo.currentGame.level
        });

        if (onCurrentGameExist) onCurrentGameExist();
      }

      usePlayerStore
        .getState()
        .setSelectedPerson(playerInfo.currentPerson.path);

      if (playerInfo.currentGame)
        useGameStore
          .getState()
          .setSelectedGameType(playerInfo.currentGame.level);
    } catch (error) {
      localStorage.removeItem(ACCESS_TOKEN_LS);
      window.location.reload();
      console.log(error);
    }
  },
  playerGet: async () => {
    try {
      const person = await playerController.personGet();

      set({ person });
    } catch (error) {
      console.log(error);
    }
  },
}));
