import cx from 'classnames';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Download } from '../../images/svg';
import { useGameStore } from '../../store/game';
import { usePlayerStore } from '../../store/player';
import { Card } from '../Card';
import { CustomButton } from '../shared';
import classes from './Certification.module.scss';
const Certification = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const printDocument = () => {
    window.scrollTo(0, 0);

    html2canvas(document.querySelector('#capture'), {
      quality: 1,
      scale: 3,
      allowTaint: true,
      scrollY: 0.25,
      removeContainer: false
    }).then((dataUrl) => {
      const pdf =
        window.innerWidth < 991
          ? jsPDF('l', 'pt', [180, 120])
          : new jsPDF('l', 'pt', [dataUrl.width, dataUrl.height]);

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(dataUrl, 'SVG', 0, 0, width, height);
      pdf.save('Certificate.pdf');
    });
  };

  const { certificateGet, certificate } = useGameStore();
  console.log('certificateGet', certificateGet);
  const userFullname = certificateGet?.fullName.length > 40 ? true : false;
  const userFullname2 = certificateGet?.fullName.length > 19 ? true : false;
  // sectionArmenianText
  // sectionEnglishText
  // sectionRussianText
  const { selectedLanguage } = usePlayerStore();
  // const userFullname = false;
  useEffect(() => {
    certificate(match.params.gameType);
  }, [userFullname]);

  return (
    <div className={classes.certificationPage}>
      <Card classStyle={window.innerWidth > 991 && classes.card}>
        {/* {window.innerWidth <= 991 && (
          <>
            <Logo />
            <div className={classes.certificateTitle}>Հավաստագիր</div>
          </>
        )} */}
        <div className={classes.CertificateWrapper} id='capture'>
          <div className={classes.certificateTextWrapper}>
            <p className={userFullname ? classes.smallTitle : classes.title}>
              {t('text.certificate')}
            </p>
            {/* <p className={classes.titleEn}>CERTIFICATE OF ACHIEVEMENT</p> */}
            <p className={classes.presented}>
              {selectedLanguage === 'Am' && 'Տրվում է'}
            </p>
            <p className={userFullname ? classes.smallName : classes.name}>
              {`${certificateGet?.fullName}${
                selectedLanguage === 'Am' ? 'ին' : ''
              }`}
            </p>
            <div className={classes.aboutText}>
              {/* <p className={classes.about}>
                {certificateGet?.armenianText}
              </p>
              <p className={classes.aboutEn}>{certificateGet?.englishText}</p> */}
              {selectedLanguage === 'Am' ? (
                <p className={classes.about}>{certificateGet?.armenianText}</p>
              ) : selectedLanguage === 'En' ? (
                <p className={classes.aboutEn}>{certificateGet?.englishText}</p>
              ) : (
                <p className={classes.aboutEn}>{certificateGet?.russianText}</p>
              )}
            </div>
            <div className={classes.signatureWrapper}>
              <p className={classes.date}>
                {moment(certificateGet?.gameComletedDate).format('DD.MM.YYYY')}
              </p>
              <p className={classes.place}>
                {selectedLanguage === 'Am' ? (
                  <p className={classes.about}>
                    {certificateGet?.communityArmenianText}
                  </p>
                ) : selectedLanguage === 'En' ? (
                  <p className={classes.aboutEn}>
                    {certificateGet?.communityEnglishText}
                  </p>
                ) : (
                  <p className={classes.aboutEn}>
                    {certificateGet?.communityRussianText}
                  </p>
                )}
              </p>

              <div className={classes.signature}></div>

              {selectedLanguage === 'Am' ? (
                <p className={classes.footerText}>
                  &#171;ՏԻՄխաղա&#187; առցանց խաղը ստեղծվել է ՄԱԶԾ <br />
                  &#171;Կանայք և երիտասարդները նորարար տեղական զարգացման
                  գործընթացներում&#187; ծրագրի շրջանակներում, որը ֆինանսավորում
                  է Շվեյցարիայի զարգացման և Համագործակցության գործակալության
                  կողմից և իրականցվում է ՀՀ տարածքային կառավարմա և
                  ենթակառուցվածքների նախարարության հետ համագործակցությամբ։
                  <br />
                  &#171;ՏԻՄխաղա&#187; առցանց խաղը զարգացվել է &#171;ՀԿ
                  Կենտրոն&#187; ՔՀԶ ՀԿ-ի կողմից։
                </p>
              ) : selectedLanguage === 'En' ? (
                <p className={classes.footerText}>
                  &#171;ՏԻՄխաղա&#187; online game was created by the UNDP
                  program <br />
                  &#171;Women and Youth for Innovative Local Development,&#187;
                  which is financed by the Swiss Development and Cooperation
                  Agency and is implemented by the cooperation with the Ministry
                  of Territorial Administration and Infrastructure of the RA.
                  <br />
                  &#171; ՏԻՄ խաղա&#187;online game was developed &#171;with the
                  support of "NGO Center" CSDP NGO.
                </p>
              ) : selectedLanguage === 'Ru' ? (
                <p
                  className={classes.footerText}
                  style={{
                    fontSize: '0.78rem'
                  }}
                >
                  Онлайн игра «Играй в ОМС» (Органы Местного Самоуправления)
                  была создана в рамках программы развития ООН «Женщины и
                  молодежь в процессах инновационного локального развития» по
                  Программе Развития ООН, которая финансируется агентством
                  развития и сотрудничества Швейцарии и реализуется в
                  сотрудничестве с министерством регионального управления и
                  инфраструктуры РА.
                  <br />
                  Игра была развита Общественной Организацией Развития
                  Гражданского Общества «НКО Кентрон».
                </p>
              ) : (
                <p className={classes.footerText}>
                  &#171;ՏԻՄխաղա&#187; առցանց խաղը ստեղծվել է ՄԱԶԾ <br />
                  &#171;Կանայք և երիտասարդները նորարար տեղական զարգացման
                  գործընթացներում&#187; ծրագրի շրջանակներում, որը ֆինանսավորում
                  է Շվեյցարիայի զարգացման և Համագործակցության գործակալության
                  կողմից և իրականցվում է ՀՀ տարածքային կառավարմա և
                  ենթակառուցվածքների նախարարության հետ համագործակցությամբ։
                  <br />
                  &#171;ՏԻՄխաղա&#187; առցանց խաղը զարգացվել է &#171;ՀԿ
                  Կենտրոն&#187; ՔՀԶ ՀԿ-ի կողմից։
                </p>
              )}
            </div>
            {params.gameType === 'ActiveResidentOfTheCommunity' ? (
              <div className={classes.certificationMedal}></div>
            ) : (
              <div
                className={
                  userFullname
                    ? classes.smallCertificationMedal2
                    : classes.certificationMedal2
                }
              ></div>
            )}
          </div>
        </div>
        <div
          className={cx(
            'd-flex',
            'mt-3',
            window.innerWidth <= 991
              ? 'justify-content-center'
              : 'justify-content-end',
            `${classes.buttonWrapper}`
          )}
        >
          <CustomButton
            title={t('text.download')}
            className='mt-3 pb-3 d-flex justify-content-end mr-3'
            onClick={printDocument}
            renderedIcon={<Download className='mr-3' />}
          />
          <CustomButton
            title={t('text.next')}
            onClick={() => history.replace('/')}
            className={cx(
              'mt-3',
              'pb-3',
              'd-flex',
              'justify-content-end',
              'mr-2',
              `${classes.nextButton}`
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default React.memo(Certification);
