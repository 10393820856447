import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Contact, LanguageSelect } from '..';
import { Medal, WhiteLogo } from '../../images/svg';
import { useGameStore } from '../../store/game';
import { usePlayerStore } from '../../store/player';
import { useSectionStore } from '../../store/section';
import { useUserStore } from '../../store/users';
import { Card } from '../Card';
import classes from '../ChooseSection/ChooseSection.module.scss';
import { CustomButton, CustomSideNav, Title } from '../shared';
const ChooseSection = () => {
  const { section, getSection } = useSectionStore();
  const { user: val } = useUserStore();

  const {
    getGame,
    isShowedButtonLoading,
    selectedGameType,
    id,
    setSelectedGameType
  } = useGameStore();
  const {
    selectedPerson,
    user,
    selectedLanguage,
    communityBudgetCompleted,
    candidateForTheCouncilOfElders,
    communityAnnualWorkPlanCompleted,
    communityFiveYearPlanCompleted
  } = usePlayerStore();

  const [set1, showSet1] = useState(null);
  const [set2, showSet2] = useState(null);
  const [set3, showSet3] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [sectionn, setSection] = useState('');

  const first = () => {
    if (communityBudgetCompleted) return;

    showSet1(true);
    showSet2(false);
    showSet3(false);
    setSection('CommunityBudget');
  };
  const second = () => {
    if (communityAnnualWorkPlanCompleted) return;

    showSet2(true);
    showSet1(false);
    showSet3(false);
    setSection('AnnualWorkPlanOfCommunity');
  };
  const third = () => {
    if (communityFiveYearPlanCompleted) return;
    showSet3(true);
    showSet2(false);
    showSet1(false);
    setSection('CommunityFiveYearPlan');
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!selectedPerson) history.replace('/');
  }, []);

  return (
    <div className={classes.ChooseSection}>
      <Card>
        <div className={classes.SectionHeader}>
          <div className='d-none d-lg-flex justify-content-end'>
            <LanguageSelect />
            <Contact />
          </div>
        </div>
        <div className={classes.CardHeader}>
          <div className='d-flex d-lg-none justify-content-between align-items-center'>
            <div className={classes.CardHeaderLogo}>
              <WhiteLogo />
            </div>
            <CustomSideNav />
          </div>
        </div>

        <div className={classes.SectionGroupWrapper}>
          <div className={classes.SectionGroupContainer}>
            <div className={classes.Title}>
              <Title>{t('text.choose_section')}</Title>
            </div>
            <div className={classes.SectionGroup}>
              <div
                onClick={first}
                style={
                  set1
                    ? { border: '2px solid #FFB400' }
                    : { border: '1px solid white' }
                }
                className={`${
                  communityBudgetCompleted ? classes.CompletedGame : ''
                }`}
              >
                {communityBudgetCompleted && (
                  <div className={classes.CheckMedal}>
                    <Medal />
                  </div>
                )}
                <p style={set1 ? { color: '#FFB400' } : { color: 'white' }}>
                  {t('text.section1')}
                </p>
              </div>

              <div
                onClick={second}
                style={
                  set2
                    ? { border: '2px solid #FFB400' }
                    : { border: '1px solid white' }
                }
                className={`${
                  communityAnnualWorkPlanCompleted ? classes.CompletedGame : ''
                }`}
              >
                {communityAnnualWorkPlanCompleted && (
                  <div className={classes.CheckMedal}>
                    <Medal />
                  </div>
                )}
                <p style={set2 ? { color: '#FFB400' } : { color: 'white' }}>
                  {t('text.section2')}
                </p>
              </div>
              <div
                onClick={third}
                style={
                  set3
                    ? { border: '2px solid #FFB400' }
                    : { border: '1px solid white' }
                }
                className={`${
                  communityFiveYearPlanCompleted ? classes.CompletedGame : ''
                }`}
              >
                {communityFiveYearPlanCompleted && (
                  <div className={classes.CheckMedal}>
                    <Medal />
                  </div>
                )}
                <p style={set3 ? { color: '#FFB400' } : { color: 'white' }}>
                  {t('text.section3')}
                </p>
              </div>
            </div>

            <div>
              <CustomButton
                buttonIcon={isShowedButtonLoading ? 'spinner-border' : ''}
                title={isShowedButtonLoading ? '' : `${t('text.next')}`}
                className={cx(
                  set1 || set2 || set3
                    ? classes.CustomButtonAllow
                    : classes.CustomButton,
                  {
                    [classes.CustomButtonCompleted]:
                      candidateForTheCouncilOfElders
                  }
                )}
                disabled={set1 || set2 || set3 ? false : true}
                onClick={() => {
                  getSection(sectionn);
                  setSelectedGameType(sectionn);
                }}
              />
            </div>
          </div>
        </div>

        {/* {candidateForTheCouncilOfElders &&
        communityAnnualWorkPlanCompleted &&
        communityFiveYearPlanCompleted
          ? null
          : !user &&
            selectedPerson && (
              <HelperPerson
                name='choose-section'
                overlay
                hideAfter={5000}
                isRight
                personSpeech={t('text.choose_level')}
              />
            )} */}
      </Card>
    </div>
  );
};

export default ChooseSection;
