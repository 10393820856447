import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import classes from '../CustomButton/CustomButton.module.scss';

const CustomButton = ({
  className,
  buttonIcon,
  renderedIcon,
  title,
  ...props
}) => {
  return (
    <div className={`${classes.CustomButton} ${className || ''}`}>
      <button type='submit' {...props}>
        {renderedIcon || <i className={cx(buttonIcon)}></i>}
        {title}
      </button>
    </div>
  );
};

export default React.memo(CustomButton);
