import React from 'react';
import { CustomSelect } from '../shared';
import classes from './LanguageSelect.module.scss';

const LanguageSelect = () => {
  return (
    <div className={classes.Language}>
      <CustomSelect />
    </div>
  );
};
export default React.memo(LanguageSelect);
