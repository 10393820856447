import * as Yup from 'yup';

const UserLoginValidation = (t) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(t('validation_text.required'))
      .min(3, t('validation_text.username_login_min'))
      .max(20, t('validation_text.username_login_max'))
      .matches(/^[a-zA-Zա-ևԱ-ֆа-яА-Я]+$/, t('validation_text.name_not_number')),

    lastName: Yup.string()
      .required(t('validation_text.required'))
      .min(3, t('validation_text.username_login_min'))
      .max(20, t('validation_text.username_login_max'))
      .matches(
        /^[a-zA-Zա-ևԱ-ֆа-яА-Я]+$/,
        t('validation_text.surename_not_number')
      ),

    middleName: Yup.string()
      .required(t('validation_text.required'))
      .min(3, t('validation_text.username_login_min'))
      .max(20, t('validation_text.username_login_max'))
      .matches(
        /^[a-zA-Zա-ևԱ-ֆа-яА-Я]+$/,
        t('validation_text.middlename_not_number')
      ),
    community: Yup.string().required(t('validation_text.required')),
    state: Yup.string().required(t('validation_text.required')),
    residence: Yup.string()
      .required(t('validation_text.required'))
      .min(2, t('validation_text.username_login_min'))
      .max(100, t('validation_text.username_login_max')),
    age: Yup.number()
      .typeError(t('validation_text.number'))
      .required(t('validation_text.required'))
      .positive()
      .integer()
      .test(
        'no-leading-zero',
        t('validation_text.correct_age'),
        (value, context) => {
          return (
            context.originalValue && !context.originalValue.startsWith('0')
          );
        }
      )
      .min(3, t('validation_text.min_age'))
      .max(100, t('validation_text.max_age')),
    email: Yup.string()
      .email(t('validation_text.invalid_email'))
      .required(t('validation_text.required')),
    phone: Yup.string()
      .required(t('validation_text.required'))
      .matches(
        /^(([+374]{4}|[0]{1}|[374]{3}))?(4[134]|55|33|77|9[1345689])(\d{6})$/,
        {
          message: t('validation_text.invalid_phone'),
          excludeEmptyString: true
        }
      )
  });

export default UserLoginValidation;
