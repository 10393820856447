import create from 'zustand';
import { gameController } from '../services/controllers';

export const useGameStore = create((set, get) => ({
  questions: [],
  id: null,
  currentStep: 1,
  percent: 0,
  isShowedButtonLoading: false,
  rightQuestionAnswer: null,
  isBlockedAnswerClick: false,
  score: 0,
  selectedGameType: '',
  certificateInfo: [],
  setSelectedGameType: (gameType) => set({ selectedGameType: gameType }),

  resetId: (percent) => {
    set({ id: null });
  },

  getCurrentQuestion: () => get().questions[get().currentStep - 1],
  isShowedInvalidParametersSTart: false,
  // changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  getGame: async (gameData, onSuccess = () => {}, onError = () => {}) => {
    try {
      set({ percent: 0, currentStep: 1, isShowedButtonLoading: true });

      const game = await gameController.getGame(gameData);

      set({
        questions: game.questions,
        id: game._id,
        selectedGameType: game.level
      });

      onSuccess();
    } catch (error) {
      console.log(error);
      onError();
    } finally {
      set({ isShowedButtonLoading: false });
      set({ isShowedInvalidParametersSTart: false });
    }
  },

  checkGame: async (answer, onComplete) => {
    try {
      set({ isBlockedAnswerClick: true });

      const gameAnswer = await gameController.checkGameAnswer(answer);

      set({ rightQuestionAnswer: gameAnswer.trueAnswer });

      setTimeout(() => {
        const newStep = get().currentStep + 1;

        if (newStep === 11) {
          set({ questions: [] });
          get().complete(() => {
            set({ currentStep: newStep });
            set({ isBlockedAnswerClick: false, rightQuestionAnswer: null });

            onComplete();
          });
        } else {
          set({ currentStep: newStep });
          set({ isBlockedAnswerClick: false, rightQuestionAnswer: null });
        }
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  },

  complete: async (onComplete) => {
    try {
      const gameAnswer = await gameController.completeGame(get().id);

      set({ percent: gameAnswer.percent, score: gameAnswer.score });

      onComplete();
    } catch (error) {
      console.log(error);
    }
  },

  certificate: async (level) => {
    try {
      const certificateGet = await gameController.certificateGet(level);
      set({ certificateGet });
    } catch (error) {
      console.log(error);
    }
  }
}));
