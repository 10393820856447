import create from 'zustand';
import { emailController } from '../services/controllers';

export const useEmailStore = create((set, get) => ({
  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,
  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),
  isSuccess: false,
  setIsSuccess: (isSuccess) => set({ isSuccess }),
  email: async (values, onSuccess) => {
    try {
      set({ isShowedButtonLoading: true });

      await emailController.email({
        subject: '',
        email: values.email,
        text: values.msg,
        name: values.name
      });
      set({ isSuccess: true });

      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      set({ isShowedButtonLoading: false });
      set({ isShowedInvalidParameters: false });
    }
  }
}));
