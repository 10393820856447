import apiService from '../api';

class PlayerController {
  async playerLang(langData) {
    try {
      const playerResponse = await apiService.post(
        `/Player/ChangePlayerLanguage?language=${langData}`
      );

      return playerResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;
      return Promise.reject(errorInfo);
    }
  }

  async playerInfo() {
    try {
      const { data: playerInfoResponse } = await apiService.post(
        '/Player/GetPlayerInfoByAccessToken'
      );

      return playerInfoResponse.data;
    } catch (error) {
      const errorInfo = error.response.data;
      return Promise.reject(errorInfo);
    }
  }

  async personGet() {
    try {
      const { data: personData } = await apiService.get('/Player/GetPersons');
      return personData.data;
      // return [
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      //   {
      //     name: 'x',
      //     path: 'https://localhost:44366/PersonImages/Person1_Boy/Person1_Boy'
      //   },
      // ];
    } catch (error) {
      const errorInfo = error.response.data;
      return Promise.reject(errorInfo);
    }
  }

  async selectPerson(personId) {
    try {
      const { data: personData } = await apiService.get(
        `/Player/ChangePerson/?personId=${personId}`
      );

      return personData.data;
    } catch (error) {
      const errorInfo = error.response.data;
      return Promise.reject(errorInfo);
    }
  }

  async changePerson(personId) {
    try {
      const { data: personData } = await apiService.get(
        `/Player/ChangePerson?personId=${personId}`
      );

      return personData.data;
    } catch (error) {
      const errorInfo = error.response.data;
      return Promise.reject(errorInfo);
    }
  }
}

const playerController = new PlayerController();

export default playerController;
