import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Stars } from '../../../images/svg';
import { ReactComponent as CloseIcon } from '../../../images/svg/close.svg';
import { useGameStore } from '../../../store/game';
import { usePlayerStore } from '../../../store/player';
import { useSectionStore } from '../../../store/section';
import { CustomButton } from '../CustomButton';
import CustomIndicatorResult from '../CustomIndicatorResult/CustomIndicatorResult';
import classes from '../ModalGroup/ModalHappy.module.scss';

const ModalHappy = ({ percent, score, defaultOpened, color }) => {
  const [open, setOpen] = useState(defaultOpened || false);
  const { selectedPerson } = usePlayerStore();
  const { selectedGameType } = useGameStore();
  const history = useHistory();
  const { section, getSection } = useSectionStore();

  const { resetId } = useGameStore();
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    resetId(percent);
    getSection(null);
    history.replace('/');
  };

  return (
    <div>
      <div className={classes.ModalHappy}>
        <Dialog open={open} onClose={handleClose} className={classes.Modal}>
          <div className={classes.ModalHeader}>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className={classes.ModalBody}>
            <div className='text-center'>
              <Stars className={classes.Stars} />
            </div>
            <div className={classes.CircleGroup}>
              <CustomIndicatorResult percent={percent} color='yellow' />
              <span>{percent} %</span>
            </div>
            <div className={classes.Title}>
              <h2> {t('text.congratulation')}</h2>
              <p>
                {t('text.congratulation_text_1')} {score}{' '}
                {t('text.congratulation_text_2')}{' '}
                {selectedGameType === 'ActiveResidentOfTheCommunity'
                  ? t('text.congratulation_text_4')
                  : t('text.congratulation_text_3')}
              </p>
            </div>
            {/* <img
              src={`${selectedPerson}.svg`}
              className={classes.HappyPerson}
              alt=''
            /> */}

            <div className='text-center mt-3'>
              <CustomButton
                title={t('text.get_certification')}
                onClick={() => {
                  window.location.replace(`/certification/${selectedGameType}`);
                }}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default React.memo(ModalHappy);
