import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArmFlag, EnFlag, Error, RuFlag } from '../../../images/svg';
import { useCommunityStore } from '../../../store/community';
import { usePlayerStore } from '../../../store/player';

import { useUserStore } from '../../../store/users';
import classes from './CustomSelect.module.scss';

export default React.memo(function CustomSelect({
  className,
  medium = false,
  en = 'En',
  am = 'Հայ',
  ru = 'Ру',
  label,
  error,
  name,
  title = '',
  placeholder,
  state,
  ...props
}) {
  const {
    selectedValue,
    setSelectedValue,
    setPlayerLang,
    changePlayerLang,
    selectedLanguage
  } = usePlayerStore();

  const { communityGet, community } = useCommunityStore();

  const { user } = useUserStore();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!state) return;

    communityGet(state);
  }, [state]);

  const tranformData = community?.data?.filter(
    (com) => selectedLanguage === com.language
  );

  return (
    <div
      className={cx(
        {
          [classes.CustomSelectCommunity]: medium,
          [classes.CustomSelect]: !medium
        },
        className
      )}
    >
      {medium && (
        <label htmlFor='outlined-basic' className={cx({}, className)}>
          {title} <span className='text-danger'>*</span>
        </label>
      )}
      <div
        className={cx(
          classes.CustomSelectRoot,
          {
            [classes.ErrorBorder]: error
          },
          className
        )}
        id='outlined-basic'
      >
        {medium ? (
          <FormControl>
            <Select
              {...props}
              MenuProps={{
                className: classes.CustomSelectMenu
              }}
              disableUnderline
              onChange={(event) => {
                props.onChange?.(event);

                setSelectedValue(event.target.value);
              }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                <span className={classes.Placeholder}>{placeholder}</span>
              </MenuItem>
              {tranformData?.map((value) => (
                <MenuItem
                  className={classes.SelectTextContainer}
                  value={value.name}
                >
                  <span
                    className={cx(classes.SelectTextCommunity, {
                      [classes.customSelectText]: selectedValue
                    })}
                  >
                    {value.name}
                  </span>
                </MenuItem>
              ))}
            </Select>

            {error ? <Error className={classes.ErrorIcon} /> : null}
          </FormControl>
        ) : (
          <FormControl>
            <Select
              {...props}
              MenuProps={{
                className: classes.LanguageCustomSelectMenu
              }}
              disableUnderline
              onChange={(event) => {
                i18n.changeLanguage(event.target.value);
                setPlayerLang(event.target.value);
                if (user) changePlayerLang();
              }}
              value={selectedLanguage}
            >
              <MenuItem value={'Am'}>
                <ArmFlag />
                <span className={classes.SelectText}>{am}</span>
              </MenuItem>
              <MenuItem value={'En'}>
                <EnFlag />
                <span className={classes.SelectText}>{en}</span>
              </MenuItem>
              <MenuItem value={'Ru'}>
                <RuFlag />
                <span className={classes.SelectText}>{ru}</span>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
});
