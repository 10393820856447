import * as Yup from "yup";

const ContactUsValidation = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t("validation_text.required"))
      .min(2, t("validation_text.username"))
      .max(20, t("validation_text.username"))
      .label("Անունը"),
    email: Yup.string()
      .required(t("validation_text.required"))
      .max(40, t("validation_text.mail_length"))
      .matches(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, t("validation_text.mail")),
    msg: Yup.string()
      .required(t("validation_text.required"))
      .max(300, t("validation_text.max_length")),
  });

export default ContactUsValidation;
