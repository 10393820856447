import React from 'react';
import './CustomIndicatorResult.module.scss';
const CustomIndicatorResult = ({ percent, color }) => {
  return (
    <svg
      className='white-circle'
      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g className='white-circle__circle'>
        <circle
          className={`white-circle__${color}`}
          cx='50'
          cy='50'
          r='45'
        ></circle>
        <path
          id='base-timer-path-remaining'
          strokeDasharray={`${283 - (283 * percent) / 100} 283`}
          className='white-circle__remaining arc'
          d=' M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 '
          style={{
            transition: 'all 1s linear 0s'
          }}
        ></path>
      </g>
    </svg>
  );
};

export default React.memo(CustomIndicatorResult);
