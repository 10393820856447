import React from 'react';
import classes from '../../shared/Progress/Progress.module.scss';

const Progress = ({ currentStep }) => {
  return (
    <div className={classes.Progress}>
      <div
        className='progress'
        style={{
          backgroundColor: '#6489FF',
          borderRadius: '1.2em',
          height: '1.8rem',
          position: 'relative'
        }}
      >
        <div
          className='progress-bar'
          role='progressbar'
          style={{
            width: `calc(${currentStep * 9.9}%)`,
            backgroundColor: '#19215E',
            position: 'absolute',
            top: '0.4rem',
            left: '0.4rem',
            height: '1rem',
            borderRadius: '1.2em'
          }}
          aria-valuenow='0'
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
};

export default React.memo(Progress);
