import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Error } from '../../../images/svg';
import classesStyles from '../CustomInput/CustomInput.module.scss';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
});

const CustomInput = ({
  title = '',
  placeholder = 'placeholder',
  type = 'text',
  maxLength,
  error,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classesStyles.CustomInput}>
      <label htmlFor='outlined-basic'>
        {title} <span className='text-danger'>*</span>
      </label>
      <div className={classesStyles.root}>
        <TextField
          id='outlined-basic'
          className={error ? classesStyles.ValidationBorder : ''}
          type={type}
          placeholder={placeholder}
          InputProps={{ classes }}
          inputProps={{
            onBlur: () => {
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 100);
            }
          }}
          {...props}
        />
        {error ? <Error className={classesStyles.ErrorIcon} /> : null}
      </div>
    </div>
  );
};
export default React.memo(CustomInput);
