import checkIsDesktop from './checkIsDesktop';

let timeout;

const changeRootSize = () => {
  const onResize = () => {
    const isMobileOrTablet = !checkIsDesktop();

    const inner = isMobileOrTablet
      ? window.screen.width < window.screen.height
        ? window.screen.width
        : window.screen.height
      : window.innerWidth;

    const baseWindowWidth = isMobileOrTablet ? 375 : 1152;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      const defaultFontSize = isMobileOrTablet ? 10 : 7.5;

      const fontSize = (defaultFontSize * inner) / baseWindowWidth;

      document.querySelector('html').style.fontSize = `${fontSize}px`;
    }, 100);
  };

  onResize();

  window.addEventListener('resize', onResize);

  return () => window.removeEventListener('resize', onResize);
};

export default changeRootSize;
