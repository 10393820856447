import create from 'zustand';
import { StatusCodes } from '../configs/api';
import { ACCESS_TOKEN_LS } from '../configs/storage';
import apiService from '../services/api';
import { authController, playerController } from '../services/controllers';
import { usePlayerStore } from './player';

export const useUserStore = create((set, get) => ({
  user: Boolean(localStorage.getItem(ACCESS_TOKEN_LS)),

  isShowedInvalidParameters: false,
  isShowedButtonLoading: false,
  changeInvalidParameter: () => set({ isShowedInvalidParameters: false }),

  isLoginInvalidParametrs: false,
  isLoginButtonLoading: false,
  changeLoginInvalidParametr: () => set({ isLoginInvalidParametrs: false }),

  isResetInvalidParametrs: false,
  isResetButtonLoading: false,
  changeResetInvalidParametr: () => set({ isResetInvalidParametrs: false }),
  isSuccess: false,

  setIsSuccess: (isSuccess) => set({ isSuccess }),
  a: '',
  // REGISTER
  register: async (values, onSuccess, onInvalidParameters) => {
    try {
      set({ isShowedButtonLoading: true });

      const user = await authController.register({
        firstName: values.name,
        lastName: values.surname,
        userName: values.nickName,
        password: values.password,
        confirmPassword: values.passwordRepeat
      });

      localStorage.setItem(ACCESS_TOKEN_LS, user.token);
      apiService.setAccessToken(user.token);

      set({ user: true });

      usePlayerStore.getState().changePlayerLang();

      onSuccess();
    } catch (error) {
      if (error.status === StatusCodes.USERNAME_EXIST) {
        if (onInvalidParameters) onInvalidParameters();

        set({ isShowedInvalidParameters: true });
      }
      console.log(error);
    } finally {
      set({ isShowedButtonLoading: false });
    }
  },

  // LOGIN
  login: async (values, onInvalidParameters) => {
    try {
      set({ isLoginButtonLoading: true });

      const user = await authController.login(values);

      localStorage.setItem(ACCESS_TOKEN_LS, user.token);
      apiService.setAccessToken(user.token);

      await usePlayerStore.getState().changePlayerLang();
      await playerController.selectPerson(localStorage.getItem('personId'));
      usePlayerStore.getState().getPlayerInfo(() => set({ user }));
      // set({ a: p_id });
    } catch (error) {
      console.log(error);
      if (error.status === StatusCodes.INVALID_PARAMETERS) {
        if (onInvalidParameters) onInvalidParameters();

        set({ isLoginInvalidParametrs: true });
      }
    } finally {
      set({ isLoginButtonLoading: false });
    }
  },

  // RESET
  reset: async (values, onSuccess = () => {}) => {
    try {
      set({ isResetButtonLoading: true });

      await authController.reset({
        userName: values.userName,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.repeatNewPassword
      });

      set({ user: null });
      set({ isSuccess: true });
      localStorage.removeItem(ACCESS_TOKEN_LS);

      setTimeout(onSuccess, 2000);
    } catch (error) {
      if (error.status === StatusCodes.INCORRECT_OLD_PASSWORD_OR_USERNAME)
        set({ isResetInvalidParametrs: true });
      console.log(error);
    } finally {
      set({ isResetButtonLoading: false });
    }
  },

  set
}));
