import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Contact } from '..';
import {
  EnFlag,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  NgocLogo,
  RuFlag
} from '../../images/svg';
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg';
import { usePlayerStore } from '../../store/player';
import { useUserStore } from '../../store/users';
import { CustomButton, Language, Title } from '../shared';
import classes from './SelectLanguageSection.module.scss';

const SelectLanguageSection = () => {
  const { t, i18n } = useTranslation();
  const [overlay, setOverlay] = useState(true);
  const [right, setRight] = useState(false);
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const { setPlayerLang, selectedPerson, selectedLanguage } = usePlayerStore();

  const { user } = useUserStore();

  const handleClose = () => {
    setOpen(false);
  };

  const changeLanuage = (language) => {
    setPlayerLang(language);
    i18n.changeLanguage(language);
    setOverlay(false);
  };
  const handleOverlay = () => {
    setOverlay(false);
    history.push('/persons');
  };
  useEffect(() => {
    if (!selectedPerson && !user) history.replace('/');
  }, []);

  return (
    <div className={classes.SelectLanguageSection}>
      <Card>
        <div className={classes.ContactSection}>
          <Contact />
        </div>
        <div className={classes.LanguageSection}>
          <Title>{t('text.selectLang')}</Title>

          <div
            onClick={() => {
              setOpen(true);
            }}
            className={classes.AboutusButton}
          >
            <span> {t('text.about_us')}</span>
          </div>

          <div className={classes.Languages}>
            <div className={classes.LanguagesContainer}>
              <Language
                name='Հայերեն'
                selected={selectedLanguage === 'Am'}
                handleChosseLangiage={() => {
                  changeLanuage('Am');
                }}
                selectedClass={
                  selectedLanguage === 'Am'
                    ? classes.SelectedLanguage
                    : classes.Language
                }
              />
              <Language
                name='English'
                selected={selectedLanguage === 'En'}
                icon={<EnFlag />}
                handleChosseLangiage={() => {
                  changeLanuage('En');
                }}
                selectedClass={
                  selectedLanguage === 'En'
                    ? classes.SelectedLanguage
                    : classes.Language
                }
              />
              <Language
                name='Русский'
                selected={selectedLanguage === 'Ru'}
                icon={<RuFlag />}
                handleChosseLangiage={() => {
                  changeLanuage('Ru');
                }}
                selectedClass={
                  selectedLanguage === 'Ru'
                    ? classes.SelectedLanguage
                    : classes.Language
                }
              />
            </div>

            {/* <Link to='/login'> */}
            <CustomButton
              title={t('text.next')}
              onClick={handleOverlay}
              className={right ? classes.NextButtonRight : ''}
            />
            {/* </Link> */}
          </div>
          {/* <InformationBlock /> */}
        </div>
        {/* {overlay && selectedPerson && (
          <HelperPerson
            name='language-select'
            overlay={overlay}
            isLanguageSelect
            isRight={!checkIsDesktop()}
            personSpeech={t('text.hello')}
            className={classes.Person}
          />
        )} */}
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.AboutusDialog}
      >
        <div className={classes.DialogContainer}>
          <div className={classes.AboutusHeader}>
            <h2 className={classes.Title}> {t('text.about_us')}</h2>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className={classes.Aboutus}>
            {t('text.about_1')}
            <br />
            {t('text.about_2')} <br />
            {t('text.about_3')} <br />
            {t('text.about_4')}
            <br />
            {t('text.about_5')}
          </div>
          <div className={classes.Aboutus}>
            <div>
              {t('text.about_questions')} <br />
              {t('text.about_address')}
              <br /> {t('text.about_info')}՝{'   '}
              <span>info@ngoc.am</span>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <a
                href='https://ngoc.am/en/'
                target='_blank'
                rel='noreferrer'
                className={(classes.AboutusIcon, classes.Ngoc)}
              >
                <NgocLogo />
              </a>

              <a
                href='https://www.facebook.com/ngocenterarm/'
                target='_blank'
                rel='noreferrer'
                className={classes.AboutusIcon}
              >
                <FacebookLogo />
              </a>

              <a
                href='https://www.instagram.com/ngo.center/'
                target='_blank'
                rel='noreferrer'
                className={classes.AboutusIcon}
              >
                <InstagramLogo />
              </a>

              <a
                href='https://www.linkedin.com/company/ngoc/'
                target='_blank'
                rel='noreferrer'
                className={classes.AboutusIcon}
              >
                <LinkedinLogo />
              </a>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SelectLanguageSection;
