import cogoToast from 'cogo-toast';
import { Error } from '../images/svg';

export const showAlert = (type, msg) => {
  if (!cogoToast[type]) return;

  cogoToast[type](msg, {
    position: 'top-right',
    renderIcon: type === 'error' ? () => <Error /> : undefined
  });
};
